import React from 'react';
import styles from '../styles/components/Footer.module.css';

const Footer = () => {
  return (
    <div className={styles.container}>
      <img src="./images/bvb-white.svg" alt="Brazilian Venture Builder"/>
    </div>
  )
}

export default Footer;