import React from 'react';
import styles from '../styles/components/Balloon.module.css';

interface BalloonProperties {
  type: "title" | "text";
  text: string;
  caracter: string;
  color: string;
  fullHeight?: boolean;
}

const Balloon = (props:BalloonProperties) => {
  return (
    <div
      className={styles.container}
      style={ props.fullHeight ? { height: "90%", display: "flex", alignItems: "center" } : { height: "unset" }}
    >
      <span className={props.type}>{props.text}</span>
      <div className={styles.ball} style={{backgroundColor: props.color}}>
        <span>{props.caracter}</span>
      </div>
    </div>
  );
}

export default Balloon;