import React, { Fragment } from 'react';
import { Button, Container, Grid, Hidden } from '@material-ui/core';
import styles from '../styles/components/Header.module.css';
import ScrollTo from "scroll-to-element";

const Header = () => {
  const menu = [
    {
      label: 'Who we are',
      destiny: 'whoweare'
    },
    {
      label: 'What we do',
      destiny: 'whatwedo'
    },
    {
      label: 'How we do',
      destiny: 'howwedo'
    },
    {
      label: 'Our business model',
      destiny: 'ourbusinessmodel'
    },
    {
      label: 'Boosted businesses',
      destiny: 'boostedbusinesses'
    },
    {
      label: 'Now what?',
      destiny: 'nowwhat'
    },
  ];

  const handleMenuButton = (e) => {
    let btnCliked = e.target.innerHTML;

    // eslint-disable-next-line array-callback-return
    menu.map( (item) => {
      if (item.label === btnCliked) {
        ScrollTo(`#${item.destiny}`, {
          duration: 500,
          offset: -72
        });
        
        // eslint-disable-next-line array-callback-return
        return;
      }
    })
  }

  return (
    <div className={styles.container}>
      <title>Brazilian Venture Builder</title>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={3} xs={12}>
            <img src="/images/bvb.svg" alt="Brazilian Venture Builder"/>
          </Grid>
          <Hidden smDown>
            <Grid item sm={9} style={{ textAlign: "right" }}>
              {menu.map( (item, index) => (
                <Fragment key={index}>
                  <Button
                    className={styles.menuButton}
                    onClick={handleMenuButton}
                  >
                    {item.label}
                  </Button>
                </Fragment>
              ))}
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
    );
}

export default Header;