import Header from '../components/Header';
import Banner from '../components/Banner';
import Section from '../components/Section';
import Footer from '../components/Footer';
import HowWeDoIt from '../components/HowWeDoIt';
import BoostedBusinesses from '../components/BoostedBusinesses';

export default function Home() {
  return (
    <div>
      <Header />
      <Banner />
      <Section
        id="whoweare"
        theme="light"
        image="./images/whoweare.svg"
        title="Who we are"
        text={["A group of experienced entrepreneurs and managers with decades of experience setting up, scaling up and exiting ventures in Brazil and Europe."]}
      />
      <Section
        id="whatwedo"
        theme="gray"
        image="./images/whatwedo.svg"
        title="What we do"
        text={["We invest our knowledge and money to make ventures boost in different stages of growth."]}
        reverse
      />
      <HowWeDoIt />
      <Section
        id="ourbusinessmodel"
        theme="light"
        image="./images/ourbusinessmodel.svg"
        title="Our business model"
        text={[
          "We believe in People. Technology is important but nowadays is almost a commodity.",
          "We provide shared services to ventures that are looking for a way to speed up growth.",
          "We invest capital as well knowledge with the goal of reaching, in the most short time frame, the biggest valuation or exit."
        ]}
      />
      <BoostedBusinesses />
      <Section
        id="nowwhat"
        theme="dark"
        image="./images/nowwhat.svg"
        title="Now what?"
        text={[
          "If you are not convinced that we are the best of the best doing what we do, please do not contact us!",
          "Otherwise you can schedule right now a 15mn talk clicking the below link and take your chance."
        ]}
        cta
      />
      <Footer />
    </div>
  )
}