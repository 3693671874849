import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import styles from '../styles/components/HowWeDoIt.module.css';
import Balloon from './Balloon';

const HowWeDoIt = () => {
  return (
    <div
      id="howwedo"
      className={styles.container}
    >
      <Container>
        <Typography
          variant="h1"
          className="title"
          align="center"
          style={{ color: "var(--white)" }}
          gutterBottom
        >
          How we do it
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={3} xs={12}>
            <Balloon
              type="text"
              caracter="1"
              color="var(--blue)"
              fullHeight
              text="Working closely with venture owners, we mentor entrepreneurs and teams to help take the leap in a sustainable way."
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Balloon 
              type="text"
              caracter="2"
              color="var(--blue)"
              fullHeight
              text="We take care of the venture’s governance by looking closely of People and Finance."
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Balloon
              type="text"
              caracter="3"
              color="var(--blue)"
              fullHeight
              text="We have a super efficient sales and post sales framework applicable to any business venture."
              />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Balloon
              type="text"
              caracter="4"
              color="var(--blue)"
              fullHeight
              text="Our tech vision and knowledge makes us the strongest venture builder in Brazil."
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default HowWeDoIt;