import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import styles from '../styles/components/Section.module.css';
import CTA from './CTA';

const Section = (props) => {
  return (
    <div 
      id={props.id}
      className={`${styles[props.theme]}`}
    >
      <Container>
        <Grid container
          spacing={2}
          direction={props.reverse ? 'row-reverse' : 'row'}
          justify="center"
          alignItems="center"
        >
          <Grid item sm={1} />
          <Grid item sm={5} xs={12}>
            <Typography
              variant="h1"
              className="title"
              gutterBottom
            >
              { props.title }
            </Typography>

            {props.text.map( (text, index) => (
              <Typography
                key={index}
                variant="body2"
                className="text"
                gutterBottom
              >
                {text}
              </Typography>
            ))}
            {props.cta && (
              <CTA />
            )}
          </Grid>
          <Grid item sm={1} />
          <Grid item sm={4} xs={12} className={styles.imgContainer}>
            <img src={props.image} alt={props.title} style={{ maxWidth: "100%" }} />
          </Grid>
          <Grid item sm={1} />
        </Grid>
      </Container>
    </div>
  );
}

export default Section;