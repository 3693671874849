import { Button } from '@material-ui/core';
import React from 'react';
import styles from '../styles/components/CTA.module.css';

const CTA = () => {
  return (
    <a href="https://wa.me/+5511950781461" target="_blank" rel="noreferrer">
      <Button
        className={styles.cta}
        variant="contained"
        size="large"
      >
        Talk to us
      </Button>
    </a>
  );
}

export default CTA;