import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import styles from '../styles/components/BoostedBusinesses.module.css';

const BoostedBusinesses = () => {
  return (
    <div
      id="boostedbusinesses"
      className={styles.container}
    >
      <Container>
        <Typography variant="h1"
          className="title"
          align="center"
          gutterBottom
        >
          Boosted businesses
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={2} xs={6}>
            <a href="https://www.smarkio.com.br/" target="_blank" rel="noreferrer">
              <div className={styles.board}>
                <div>
                  <img src="./images/smk.png" alt="Smarkio"/>
                </div>
                <p>Brazil</p>
              </div>
            </a>
          </Grid>

          <Grid item sm={2} xs={6}>
            <a href="https://adclick.pt/" target="_blank" rel="noreferrer">
              <div className={styles.board}>
              <div>
                <img src="./images/logo-aclick.png" alt="Logo"/>
              </div>
                <p>Europe</p>
              </div>
            </a>
          </Grid>

          <Grid item sm={2} xs={6}>
            <a href="https://www.pushnews.com.br/" target="_blank" rel="noreferrer">
              <div className={styles.board}>
              <div>
                <img src="./images/pushnews.png" alt="Pushnews"/>
              </div>
                <p>Brazil</p>
              </div>
            </a>
          </Grid>

          <Grid item sm={2} xs={6}>
            <a href="https://www.wemystic.com.br/" target="_blank" rel="noreferrer">
              <div className={styles.board}>
              <div>
                <img src="./images/wemystic.webp" alt="We Mystic"/>
              </div>
                <p>WorldWide</p>
              </div>
            </a>
          </Grid>

          <Grid item sm={2} xs={6}>
            <a href="https://www.manuelvaiot.com/index.php" target="_blank" rel="noreferrer">
              <div className={styles.board}>
              <div>
                <img src="./images/manuel.png" alt="Manuel"/>
              </div>
                <p>Brazil</p>
              </div>
            </a>
          </Grid>

          <Grid item sm={2} xs={6}>
            <a href="https://www.tech4h.com.br/" target="_blank" rel="noreferrer">
              <div className={styles.board}>
              <div>
                <img src="./images/tech4humans.png" alt="Tech4Humans"/>
              </div>
                <p>Brazil</p>
              </div>
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default BoostedBusinesses;