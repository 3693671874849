import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import styles from '../styles/components/Banner.module.css';
import Balloon from './Balloon';
import CTA from './CTA';

const Banner = () => {
  return (
    <div className={styles.container}>
      <Container>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <Balloon type= "title" caracter="?" color="var(--pink)" text="Want to set up a new business in Brazil?" />
            <Balloon type= "title" caracter="?" color="var(--green)" text="Are you looking to expand your business to Brazil?" />
            <Balloon type= "title" caracter="?" color="var(--blue)" text="Need help to raise capital to go outside of Brazil?" />
          </Grid>
          <Grid item sm={3}>
            <Balloon type= "title" caracter="?" color="var(--yellow)" text="Do you have a business idea and need to prove it in the Brazilian Market?" />
            <Balloon type= "title" caracter="?" color="var(--purple)" text="Struggling to scale up your business in Brazil?" />
          </Grid>
          <Grid item sm={1} />
          <Grid item sm={5}
            className={ styles.groupContainer }
          >
            <Typography variant="body1" gutterBottom style={{ color: "var(--white)" }}>
              Our Group
            </Typography>
            <img src="./images/bvb-white.svg" alt="Brazilian Venture Builder" style={{ maxWidth: "100%" }} />
            <CTA />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Banner;